const Msg2 = {
  subject: 'Your flight booking details for Melbourne',
  date: 'Today',
  contact: {
    name: 'Virgin Australia',
    email: 'noreply@virginaustralia.com'
  },  
  excerpt: 'Thanks for choosing Virgin Australia',
  body: `
Here’s your flight booking details for Melbourne
`
}

export default Msg2
