import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Application from "../components/email-client/application";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Email client | May 25 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-25.png" url="https://cssgrid31.brett.cool" pathname="/may-25" mdxType="Metatags" />
    <Application mdxType="Application" />
    <Footer date={25} prev={true} next={true} mdxType="Footer">
      <p>{`An email client, written with React and CSS Grid, and mostly at Builder’s Arms, Justin’s Airbnb apartment, `}<a parentName="p" {...{
          "href": "http://www.seamstress.com.au"
        }}>{`Seamstress`}</a>{` and `}<a parentName="p" {...{
          "href": "https://www.bodegaunderground.com.au/"
        }}>{`Bodega`}</a>{` on a boozy Saturday evening.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      